<template>
  <div class="flex items-center justify-between" data-id="heading">
    <div
      class="text-xl font-medium leading-6 text-txt md:text-2xl md:leading-8"
      :class="titleClasses"
    >
      {{ title }}
    </div>
    <Btn
      v-if="showSeeAllBtn"
      outlined
      variant="neutral"
      size="md"
      @click="onSeeAll"
    >
      {{ $t('components.dashboard.seeAll') }}
    </Btn>
  </div>
</template>

<script lang="ts">
import { Btn } from 'webcc-ui-components'

export default defineComponent({
  name: 'Heading',

  components: { Btn },

  props: {
    title: { type: String, default: '' },
    titleClasses: { type: [String, Array], default: '' },
    showSeeAllBtn: { type: Boolean, default: true },
  },

  emits: ['see-all'],

  methods: {
    onSeeAll() {
      this.$emit('see-all')
    },
  },
})
</script>
